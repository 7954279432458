import React, { useState } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';

function InviteForm({ familyId }) {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');
    setIsError(false);
    setIsLoading(true);

    try {
      const functions = getFunctions(undefined, 'australia-southeast1');
      const sendInvitation = httpsCallable(functions, 'sendInvitation');
      
      console.log('Creating invitation for:', email);
      const result = await sendInvitation({ inviteeEmail: email, familyId });

      console.log('Function result:', result);

      if (result.data.success) {
        setEmail('');
        setMessage('Invitation created successfully!');
      } else {
        throw new Error(result.data.error || 'Failed to create invitation');
      }
    } catch (error) {
      console.error('Error creating invitation:', error);
      setIsError(true);
      if (error.code === 'functions/internal') {
        setMessage(`Internal server error: ${error.message}. Please try again later.`);
      } else {
        setMessage(error.message || 'Failed to create invitation. Please try again.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="mt-6">
      <h3 className="text-lg font-semibold mb-2">Invite Partner</h3>
      <form onSubmit={handleSubmit} className="flex flex-col sm:flex-row sm:items-center">
        <input
          className="border rounded px-2 py-1 mb-2 sm:mb-0 sm:mr-2 bg-white text-black w-full sm:w-auto"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Partner Email"
          required
        />
        <button 
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded w-full sm:w-auto disabled:opacity-50 disabled:cursor-not-allowed"
          type="submit"
          disabled={isLoading}
        >
          {isLoading ? 'Creating...' : 'Create Invitation'}
        </button>
      </form>
      {message && <p className={`mt-2 ${isError ? 'text-red-500' : 'text-green-500'}`}>{message}</p>}
    </div>
  );
}

export default InviteForm;
