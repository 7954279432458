// bbsit-firebase/src/components/EventDetails.js
import React, { useState, useEffect, useCallback } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db, auth } from '../firebase';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingSpinner from './LoadingSpinner';
import EventActions from './EventActions';
import DeleteEventButton from './DeleteEventButton';
import EditEventForm from './EditEventForm';

const EventDetails = () => {
  const [eventDetails, setEventDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isCreator, setIsCreator] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [currentUserFamilyId, setCurrentUserFamilyId] = useState(null);
  const [rejectedFamilies, setRejectedFamilies] = useState([]);
  const navigate = useNavigate();
  const { eventId } = useParams();

  const loadEventDetails = useCallback(async () => {
    setLoading(true);
    setError(null);

    if (!eventId) {
      setError('No event ID provided');
      setLoading(false);
      return;
    }

    try {
      const eventRef = doc(db, 'events', eventId);
      const eventDoc = await getDoc(eventRef);
      
      if (eventDoc.exists()) {
        const eventData = eventDoc.data();
        setEventDetails({ id: eventDoc.id, ...eventData });
        
        // Fetch rejected families' names
        if (eventData.rejectedFamilies && eventData.rejectedFamilies.length > 0) {
          const rejectedFamiliesData = await Promise.all(
            eventData.rejectedFamilies.map(async (familyId) => {
              const familyDoc = await getDoc(doc(db, 'families', familyId));
              return familyDoc.exists() ? familyDoc.data().name : 'Unknown Family';
            })
          );
          setRejectedFamilies(rejectedFamiliesData);
        }
        
        // Check if current user is the creator
        const currentUser = auth.currentUser;
        if (currentUser) {
          const userRef = doc(db, 'users', currentUser.uid);
          const userDoc = await getDoc(userRef);
          if (userDoc.exists()) {
            const userData = userDoc.data();
            setIsCreator(userData.familyId === eventData.creatorFamilyId);
            setCurrentUserFamilyId(userData.familyId);
          }
        }
      } else {
        setError('No event found with the given ID');
      }
    } catch (error) {
      console.error('Error fetching event details:', error);
      setError('Failed to fetch event details');
    }
    setLoading(false);
  }, [eventId]);

  useEffect(() => {
    loadEventDetails();
  }, [loadEventDetails]);

  const handleActionComplete = () => {
    loadEventDetails();
  };

  const handleDelete = () => {
    navigate('/events'); // Redirect to events list after deletion
  };

  const openInMaps = (address) => {
    const encodedAddress = encodeURIComponent(address);
    window.open(`https://www.google.com/maps/search/?api=1&query=${encodedAddress}`, '_blank');
  };

  if (loading) return <LoadingSpinner />;
  if (error) return <p className="text-red-500">{error}</p>;
  if (!eventDetails) return <p className="text-gray-400">No event details available.</p>;

  const { name, description, startTime, endTime, points, status, attendees, location } = eventDetails;

  return (
    <div className="p-6 bg-gray-800 rounded-lg shadow-md">
      <h2 className="text-3xl font-bold text-blue-400 mb-4">{name}</h2>
      <p className="text-gray-300 mb-2">{description}</p>
      <p className="text-gray-400">
        <strong>Start Time:</strong> {startTime && new Date(startTime.seconds * 1000).toLocaleString()}
      </p>
      <p className="text-gray-400">
        <strong>End Time:</strong> {endTime && new Date(endTime.seconds * 1000).toLocaleString()}
      </p>
      <p className="text-gray-400">
        <strong>Points:</strong> {points}
      </p>
      <p className="text-gray-400">
        <strong>Status:</strong> {status}
      </p>
      {location && (
        <div className="mt-4">
          <p className="text-gray-400">
            <strong>Location:</strong>{' '}
            <span 
              className="cursor-pointer text-blue-400 hover:underline"
              onClick={() => openInMaps(location)}
            >
              {location}
            </span>
          </p>
          <button
            onClick={() => openInMaps(location)}
            className="mt-2 bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded text-sm transition duration-300 flex items-center"
          >
            <span className="material-icons mr-2">directions</span>
            Directions
          </button>
        </div>
      )}
      {attendees && attendees.length > 0 && (
        <div className="mt-4">
          <h3 className="text-xl font-semibold text-blue-300 mb-2">Attendees</h3>
          <ul className="list-disc list-inside text-gray-300">
            {attendees.map((attendee, index) => (
              <li key={index}>{attendee.name}</li>
            ))}
          </ul>
        </div>
      )}
      
      {isCreator && rejectedFamilies.length > 0 && (
        <div className="mt-4">
          <h3 className="text-xl font-semibold text-blue-300 mb-2">Unavailable Families</h3>
          <ul className="list-disc list-inside text-gray-300">
            {rejectedFamilies.map((familyName, index) => (
              <li key={index}>{familyName} is not available</li>
            ))}
          </ul>
        </div>
      )}
      
      {isCreator ? (
        <>
          <button
            onClick={() => setShowEditForm(!showEditForm)}
            className="mt-4 w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300"
          >
            {showEditForm ? 'Cancel Edit' : 'Edit Event'}
          </button>
          {showEditForm && (
            <EditEventForm
              eventId={eventId}
              onComplete={() => {
                setShowEditForm(false);
                loadEventDetails();
              }}
            />
          )}
          <DeleteEventButton eventId={eventId} onDelete={handleDelete} />
        </>
      ) : (
        <EventActions 
          event={eventDetails}
          currentUserFamilyId={currentUserFamilyId}
          onActionComplete={handleActionComplete} 
        />
      )}

      <button
        onClick={() => navigate(-1)}
        className="mt-6 bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300"
      >
        Back to Events
      </button>
    </div>
  );
};

export default EventDetails;