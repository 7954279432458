import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';

function GroupPageContent({ group, currentUser, isAdmin }) {
  const [copySuccess, setCopySuccess] = useState('');
  const [familyNames, setFamilyNames] = useState({});

  useEffect(() => {
    const fetchFamilyNames = async () => {
      const names = {};
      for (const familyId of group.members) {
        const familyDoc = await getDoc(doc(db, 'families', familyId));
        if (familyDoc.exists()) {
          names[familyId] = familyDoc.data().name;
        }
      }
      setFamilyNames(names);
    };

    fetchFamilyNames();
  }, [group.members]);

  const copyInviteCode = () => {
    navigator.clipboard.writeText(group.inviteCode)
      .then(() => {
        setCopySuccess('Copied!');
        setTimeout(() => setCopySuccess(''), 2000);
      })
      .catch(err => console.error('Failed to copy: ', err));
  };

  if (!group || !group.members) {
    return <div className="text-red-500">Error: Group data is not available.</div>;
  }

  return (
    <div className="bg-gray-800 p-6 rounded-lg">
      <h2 className="text-2xl font-bold mb-4">{group.name}</h2>
      <p className="text-gray-300 mb-4">{group.description}</p>
      <p className="text-sm text-gray-400 mb-2">Families: {group.members.length}</p>
      {isAdmin && (
        <p className="text-sm text-green-400 mb-4">Your family is the admin of this group</p>
      )}
      {group.inviteCode && (
        <div className="flex items-center mb-4">
          <p className="text-sm text-yellow-400 mr-2">Invite Code: {group.inviteCode}</p>
          <button 
            onClick={copyInviteCode}
            className="bg-blue-500 hover:bg-blue-600 text-white text-xs py-1 px-2 rounded"
          >
            Copy
          </button>
          {copySuccess && <span className="text-green-400 ml-2">{copySuccess}</span>}
        </div>
      )}
      <h3 className="text-lg font-semibold mb-2">Group Families:</h3>
      <ul className="list-none pl-0 mb-4">
        {group.members.map((familyId) => {
          const familyPoints = group.familyPoints && group.familyPoints.find(fp => fp.familyId === familyId);
          return (
            <li key={familyId} className="text-gray-300 mb-2 flex justify-between items-center">
              <span>
                {familyId === currentUser.familyId ? 'Your Family' : `${familyNames[familyId] || 'Unknown'} Family`}
                {group.adminFamilyId === familyId && ' (Admin)'}
              </span>
              <span className="text-yellow-400 font-semibold">
                {familyPoints ? `${familyPoints.points} points` : 'Points not available'}
              </span>
            </li>
          );
        })}
      </ul>
      {isAdmin && (
        <Link 
          to={`/groups/edit/${group.id}`} 
          className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Manage Group
        </Link>
      )}
    </div>
  );
}

export default GroupPageContent;