// components/LoadingSpinner.js
import React from 'react';

export function LoadingSpinner() {
  return (
    <div className="fixed inset-0 flex justify-center items-center bg-gray-950 bg-opacity-50 z-50">
      <div className="spinner w-10 h-10 relative">
        <div className="absolute inset-0 rounded-full bg-accent opacity-60 animate-ping"></div>
        <div className="absolute inset-0 rounded-full bg-accent opacity-60 animate-ping animation-delay-500"></div>
      </div>
    </div>
  );
}

export default LoadingSpinner;