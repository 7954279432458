// bbsit-firebase/src/components/EventList.js

import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, query, getDocs, getDoc, orderBy, limit, startAfter, where, doc } from 'firebase/firestore';
import { db, auth } from '../firebase';
import EventItem from './EventItem';
import LoadingSpinner from './LoadingSpinner';

const EventList = () => {
  const [events, setEvents] = useState([]);
  const [lastEvent, setLastEvent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [userFamilyId, setUserFamilyId] = useState(null);
  const [currentUserId, setCurrentUserId] = useState(null);
  const navigate = useNavigate();

  const loadEvents = useCallback(async () => {
    if (!userFamilyId) return;
    setLoading(true);
    try {
      const getFamilyGroupIds = async () => {
        const groupsRef = collection(db, 'groups');
        const q = query(groupsRef, where('members', 'array-contains', userFamilyId));
        const querySnapshot = await getDocs(q);
        return querySnapshot.docs.map(doc => doc.id);
      };

      const groupIds = await getFamilyGroupIds();
      if (groupIds.length === 0) {
        setEvents([]);
        setLastEvent(null);
        setLoading(false);
        return;
      }

      const eventsRef = collection(db, 'events');
      const q = query(
        eventsRef,
        where('groupId', 'in', groupIds),
        orderBy('startTime', 'desc'),
        limit(10)
      );
      const querySnapshot = await getDocs(q);
      const eventData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setEvents(eventData);
      setLastEvent(querySnapshot.docs[querySnapshot.docs.length - 1]);
    } catch (error) {
      console.error('Error loading events:', error);
    }
    setLoading(false);
  }, [userFamilyId]);

  useEffect(() => {
    const fetchUserInfo = async () => {
      const user = auth.currentUser;
      if (user) {
        setCurrentUserId(user.uid);
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          setUserFamilyId(userDoc.data().familyId);
        }
      }
    };

    fetchUserInfo();
  }, []);

  useEffect(() => {
    if (userFamilyId) {
      loadEvents();
    }
  }, [userFamilyId, loadEvents]);

  const loadMoreEvents = async () => {
    if (!lastEvent || !userFamilyId) return;
    setLoadingMore(true);
    try {
      const getFamilyGroupIds = async () => {
        const groupsRef = collection(db, 'groups');
        const q = query(groupsRef, where('members', 'array-contains', userFamilyId));
        const querySnapshot = await getDocs(q);
        return querySnapshot.docs.map(doc => doc.id);
      };

      const groupIds = await getFamilyGroupIds();
      if (groupIds.length === 0) {
        setLoadingMore(false);
        return;
      }

      const eventsRef = collection(db, 'events');
      const q = query(
        eventsRef,
        where('groupId', 'in', groupIds),
        orderBy('startTime', 'desc'),
        startAfter(lastEvent),
        limit(10)
      );
      const querySnapshot = await getDocs(q);
      const newEvents = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setEvents([...events, ...newEvents]);
      setLastEvent(querySnapshot.docs[querySnapshot.docs.length - 1]);
    } catch (error) {
      console.error('Error loading more events:', error);
    }
    setLoadingMore(false);
  };

  const handleCreateEvent = () => {
    navigate('/events/create');
  };

  if (!userFamilyId) {
    return <p className="text-center mt-8 text-white">Please join a family to view events.</p>;
  }

  return (
    <div className="min-h-screen bg-gray-950 text-white p-4 sm:p-8">
      <div className="max-w-4xl mx-auto">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-3xl font-bold">Events</h1>
          <button
            onClick={handleCreateEvent}
            className="bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded transition duration-300"
          >
            Create Event
          </button>
        </div>

        {loading ? (
          <LoadingSpinner />
        ) : (
          <>
            {events.length === 0 ? (
              <p className="text-center mt-8">No events found. Create an event to get started!</p>
            ) : (
              <div className="space-y-4">
                {events.map(event => (
                  <EventItem 
                    key={event.id} 
                    event={event} 
                    currentUserFamilyId={userFamilyId}
                    currentUserId={currentUserId}
                  />
                ))}
              </div>
            )}

            {lastEvent && events.length > 0 && (
              <button
                onClick={loadMoreEvents}
                className="mt-4 w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300"
              >
                {loadingMore ? 'Loading...' : 'Load More Events'}
              </button>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default EventList;