import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';
import CreateEventForm from './CreateEventForm';
import LoadingSpinner from './LoadingSpinner';

const CreateEventPage = () => {
  const [familyId, setFamilyId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchFamilyId = async () => {
      try {
        const user = auth.currentUser;
        if (user) {
          const userDocRef = doc(db, 'users', user.uid);
          const userDoc = await getDoc(userDocRef);
          if (userDoc.exists()) {
            const userData = userDoc.data();
            setFamilyId(userData.familyId);
          } else {
            console.error('No such document!');
          }
        }
      } catch (error) {
        console.error('Error fetching familyId:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchFamilyId();
  }, []);

  const handleEventCreation = (result) => {
    setSuccessMessage('Event created successfully!');
    setTimeout(() => {
      navigate('/events');
    }, 2000);
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="min-h-screen bg-gray-950 text-white p-4 sm:p-8">
      <div className="max-w-3xl mx-auto">
        <h1 className="text-3xl font-bold mb-6">Create New Event</h1>
        {successMessage && (
          <div className="bg-green-500 text-white p-4 rounded mb-4">
            {successMessage}
          </div>
        )}
        {familyId ? (
          <CreateEventForm familyId={familyId} onComplete={handleEventCreation} />
        ) : (
          <p className="text-red-500">No family ID found. Please check your profile.</p>
        )}
      </div>
    </div>
  );
};

export default CreateEventPage;