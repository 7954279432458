import React, { useState } from 'react';
import { functions } from '../firebase';
import { httpsCallable } from 'firebase/functions';
import LoadingSpinner from './LoadingSpinner';

const DeleteEventButton = ({ eventId, eventName, onDelete }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleDeleteClick = () => {
    setShowConfirmation(true);
  };

  const handleConfirmDelete = async () => {
    setLoading(true);
    setError(null);
    try {
      const deleteEvent = httpsCallable(functions, 'deleteEvent');
      const result = await deleteEvent({ eventId });
      
      if (result.data.success) {
        onDelete();
      } else {
        throw new Error(result.data.message || 'Failed to delete event');
      }
    } catch (error) {
      console.error('Error deleting event:', error);
      setError(`Failed to delete event: ${error.message}`);
    } finally {
      setLoading(false);
      setShowConfirmation(false);
    }
  };

  const handleCancelDelete = () => {
    setShowConfirmation(false);
  };

  return (
    <div className="mt-4 relative">
      {error && <div className="bg-red-500 text-white p-4 rounded mb-4">{error}</div>}
      <button
        onClick={handleDeleteClick}
        className="w-full bg-red-600 text-gray-300 font-bold py-2 px-4 rounded-md transition duration-300 ease-in-out hover:opacity-90"
      >
        Delete Event
      </button>

      {showConfirmation && (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-gray-950 p-6 rounded-lg shadow-lg max-w-md w-full">
            <h2 className="text-2xl font-bold mb-4 text-white">Confirm Deletion</h2>
            <p className="mb-6 text-gray-300">
              Are you sure you want to delete the event? This action cannot be undone.
            </p>
            <div className="flex justify-end space-x-4">
              <button
                onClick={handleCancelDelete}
                className="px-4 py-2 bg-gray-700 text-white rounded-md hover:bg-gray-600 transition duration-300"
              >
                Cancel
              </button>
              <button
                onClick={handleConfirmDelete}
                disabled={loading}
                className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 transition duration-300"
              >
                {loading ? <LoadingSpinner /> : 'Delete'}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DeleteEventButton;