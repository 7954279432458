// src/components/CreateFamilyForm.js

import React, { useState, useRef, useCallback, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useLoadScript, StandaloneSearchBox } from '@react-google-maps/api';
import LoadingSpinner from './LoadingSpinner';
import { useNavigate } from 'react-router-dom';

const libraries = ['places'];

function CreateFamilyForm() {
  const [step, setStep] = useState(1);
  const [partnerEmail, setPartnerEmail] = useState('');
  const [familyName, setFamilyName] = useState('');
  const [homeAddress, setHomeAddress] = useState('');
  const [childrenCount, setChildrenCount] = useState(0);
  const [childrenNames, setChildrenNames] = useState([]);
  const [hasPartner, setHasPartner] = useState(true);
  const [image, setImage] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const fileInputRef = useRef(null);
  const searchBoxRef = useRef(null);

  const navigate = useNavigate();
  const auth = getAuth();
  const storage = getStorage();
  const functions = getFunctions(undefined, 'australia-southeast1');

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log('User is signed in:', user.uid);
      } else {
        console.log('No user is signed in.');
        navigate('/auth');
      }
    });

    return () => unsubscribe();
  }, [navigate, auth]);

  const handlePlacesChanged = () => {
    const places = searchBoxRef.current.getPlaces();
    if (places && places.length > 0) {
      setHomeAddress(places[0].formatted_address);
    }
  };

  const handlePartnerCheck = useCallback(() => {
    setStep(2);
  }, []);

  const handleImageChange = useCallback((e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      setPreviewUrl(URL.createObjectURL(file));
    }
  }, []);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      setIsLoading(true);
      setError('');
  
      try {
        const user = auth.currentUser;
        if (!user) {
          throw new Error('No authenticated user');
        }
  
        console.log('Current user:', user.uid);
  
        let imageUrl = null;
        if (image) {
          try {
            const imageRef = ref(storage, `family-images/${user.uid}/${Date.now()}`);
            console.log('Uploading image...');
            await uploadBytes(imageRef, image);
            console.log('Image uploaded successfully');
            imageUrl = await getDownloadURL(imageRef);
            console.log('Image URL:', imageUrl);
          } catch (imageError) {
            console.error('Error uploading image:', imageError);
            setError('Failed to upload image, but continuing with family creation.');
          }
        }
  
        const createFamily = httpsCallable(functions, 'createFamily');
        console.log('Creating family...');
        const result = await createFamily({
          familyName,
          partnerEmail: hasPartner ? partnerEmail.trim() : null,
          homeAddress,
          children: childrenNames.filter((name) => name.trim() !== ''),
          imageUrl
        });
  
        console.log('Family creation result:', result.data);
  
        if (result.data.success) {
          console.log('Family created successfully');
          navigate('/family/dashboard');
        } else {
          throw new Error(result.data.message || 'Failed to create family');
        }
      } catch (error) {
        console.error('Error creating family:', error);
        if (error.code === 'permission-denied') {
          setError('Permission denied. Please check your account permissions.');
        } else if (error.code === 'not-found') {
          setError('Resource not found. Please try again or contact support.');
        } else {
          setError(`An error occurred: ${error.message}`);
        }
      } finally {
        setIsLoading(false);
      }
    },
    [
      auth,
      storage,
      familyName,
      homeAddress,
      childrenNames,
      hasPartner,
      partnerEmail,
      image,
      navigate,
      functions,
    ]
  );

  const inputClass =
    'w-full px-4 py-2 bg-gray-800 text-white border border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500';
  const buttonClass =
    'w-full px-4 py-2 bg-blue-500 text-white font-semibold rounded-lg hover:bg-blue-600 transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed';
  const labelClass = 'block text-sm font-medium text-gray-300 mb-1';

  if (loadError) {
    return <div>Error loading Google Maps API: {loadError.message}</div>;
  }

  if (!isLoaded) {
    return <div>Loading Google Maps API...</div>;
  }

  if (step === 1) {
    return (
      <div className="space-y-6">
        <h2 className="text-2xl font-bold text-blue-500">Partner Information</h2>
        <div className="flex items-center space-x-2">
          <input
            type="checkbox"
            id="hasPartner"
            checked={hasPartner}
            onChange={(e) => setHasPartner(e.target.checked)}
            className="rounded border-gray-300 text-blue-500 focus:ring-blue-500"
          />
          <label htmlFor="hasPartner" className={labelClass}>
            I have a partner
          </label>
        </div>
        {hasPartner && (
          <div>
            <label htmlFor="partnerEmail" className={labelClass}>
              Partner's email
            </label>
            <input
              id="partnerEmail"
              type="email"
              value={partnerEmail}
              onChange={(e) => setPartnerEmail(e.target.value)}
              placeholder="partner@example.com"
              className={inputClass}
            />
          </div>
        )}
        <button onClick={handlePartnerCheck} className={buttonClass}>
          Next
        </button>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div>
        <label htmlFor="familyImage" className={labelClass}>
          Family Image
        </label>
        <div className="flex items-center space-x-4">
          {previewUrl && (
            <img
              src={previewUrl}
              alt="Family"
              className="w-24 h-24 rounded-full object-cover"
            />
          )}
          <input
            type="file"
            id="familyImage"
            ref={fileInputRef}
            accept="image/*"
            onChange={handleImageChange}
            className="hidden"
          />
          <button
            type="button"
            onClick={() => fileInputRef.current?.click()}
            className="px-4 py-2 bg-gray-700 text-white rounded-lg hover:bg-gray-600 transition-colors"
          >
            Choose Image
          </button>
        </div>
      </div>
      <div>
        <label htmlFor="familyName" className={labelClass}>
          Family Name
        </label>
        <input
          id="familyName"
          type="text"
          value={familyName}
          onChange={(e) => setFamilyName(e.target.value)}
          placeholder="e.g., The Smiths"
          required
          className={inputClass}
        />
      </div>
      <div>
        <label htmlFor="homeAddress" className={labelClass}>
          Home Address
        </label>
        <StandaloneSearchBox
          onLoad={ref => {
            searchBoxRef.current = ref;
          }}
          onPlacesChanged={handlePlacesChanged}
        >
          <input
            id="homeAddress"
            type="text"
            value={homeAddress}
            onChange={(e) => setHomeAddress(e.target.value)}
            placeholder="123 Main St, City, Country"
            required
            className={inputClass}
          />
        </StandaloneSearchBox>
      </div>
      <div>
        <label htmlFor="childrenCount" className={labelClass}>
          Number of Children
        </label>
        <input
          id="childrenCount"
          type="number"
          value={childrenCount}
          onChange={(e) => {
            const count = parseInt(e.target.value);
            setChildrenCount(count);
            setChildrenNames(Array(count).fill(''));
          }}
          placeholder="0"
          min="0"
          required
          className={inputClass}
        />
      </div>
      {childrenNames.map((name, index) => (
        <div key={index}>
          <label htmlFor={`child${index}`} className={labelClass}>
            Child {index + 1} Name
          </label>
          <input
            id={`child${index}`}
            type="text"
            value={name}
            onChange={(e) => {
              setChildrenNames((prevNames) => {
                const newNames = [...prevNames];
                newNames[index] = e.target.value;
                return newNames;
              });
            }}
            placeholder={`Child ${index + 1} Name`}
            required
            className={inputClass}
          />
        </div>
      ))}
      <button type="submit" className={buttonClass} disabled={isLoading}>
        {isLoading ? <LoadingSpinner /> : 'Create Family'}
      </button>
      {isLoading && (
        <p className="text-center text-blue-500">
          Creating your family... This may take a moment.
        </p>
      )}
      {error && <p className="text-center text-red-500">{error}</p>}
    </form>
  );
}

export default CreateFamilyForm;