// bbsit-firebase/src/components/EventActions.js

import React, { useState } from 'react';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../firebase';
import LoadingSpinner from './LoadingSpinner';

const EventActions = ({ event, currentUserFamilyId, onActionComplete }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  if (!event) {
    return <p>No event data available</p>;
  }

  const handleAction = async (actionType) => {
    setLoading(true);
    setError(null);

    try {
      let result;
      if (actionType === 'CANCEL') {
        const cancelEvent = httpsCallable(functions, 'cancelEvent');
        result = await cancelEvent({ eventId: event.id });
      } else {
        const updateEventStatus = httpsCallable(functions, 'updateEventStatus');
        result = await updateEventStatus({
          eventId: event.id,
          status: actionType,
          familyId: currentUserFamilyId
        });
      }

      console.log('Event action result:', result.data);
      onActionComplete();
    } catch (error) {
      console.error('Error handling event action:', error);
      setError(`Action failed: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  const isRejected = event.rejectedFamilies && event.rejectedFamilies.includes(currentUserFamilyId);

  return (
    <div className="mt-4">
      {error && <p className="text-red-500 mb-4">{error}</p>}

      {event.status === 'OPEN' && !isRejected && (
        <>
          <button
            onClick={() => handleAction('ACCEPTED')}
            className="w-full bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded transition duration-300 mb-2"
          >
            Accept Event
          </button>

          <button
            onClick={() => handleAction('REJECTED')}
            className="w-full bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded transition duration-300"
          >
            Reject Event
          </button>
        </>
      )}

      {event.status === 'OPEN' && isRejected && (
        <button
          onClick={() => handleAction('UNREJECT')}
          className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300"
        >
          Unreject Event
        </button>
      )}

      {event.status === 'ACCEPTED' && event.acceptedByFamilyId === currentUserFamilyId && (
        <button
          onClick={() => handleAction('CANCEL')}
          className="w-full bg-yellow-600 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded transition duration-300"
        >
          Cancel Availability
        </button>
      )}

      {event.status === 'ACCEPTED' && event.acceptedByFamilyId !== currentUserFamilyId && (
        <p className="text-green-400">This event has been accepted by another family.</p>
      )}
    </div>
  );
};

export default EventActions;