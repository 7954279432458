import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../firebase'; 
import { updateProfile } from 'firebase/auth';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';   
import { doc, updateDoc, deleteDoc } from 'firebase/firestore';
import LoadingSpinner from './LoadingSpinner';

export default function Profile() {
  const [user, setUser] = useState(null);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [image, setImage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const fileInputRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      if (currentUser) {
        setUser(currentUser);   
        setName(currentUser.displayName || '');
        setEmail(currentUser.email || '');
        setImage(currentUser.photoURL || null);
      } else {
        navigate('/auth?mode=signin');
      }
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, [navigate]);

  const handleUpdate = async (e) => {
    e.preventDefault();
    setIsUpdating(true);
    setError(null);
    setSuccessMessage('');

    try {
      let photoURL = user.photoURL;

      if (fileInputRef.current?.files?.[0]) {
        const file = fileInputRef.current.files[0];
        const storage = getStorage(); 
        const storageRef = ref(storage, `profile_images/${user.uid}`);
        
        console.log('Uploading file:', file.name);
        const uploadResult = await uploadBytes(storageRef, file);
        console.log('File uploaded successfully:', uploadResult);
        
        photoURL = await getDownloadURL(storageRef);
        console.log('Download URL obtained:', photoURL);
      }

      await updateProfile(user, { displayName: name, photoURL });
      console.log('Profile updated in Firebase Auth');

      const userDocRef = doc(db, 'users', user.uid);
      await updateDoc(userDocRef, {
        name,
        email,
        photoURL,
      });
      console.log('User document updated in Firestore');

      setImage(photoURL);
      setSuccessMessage('Profile updated successfully!');
      
      // Clear success message after 3 seconds
      setTimeout(() => setSuccessMessage(''), 3000);
    } catch (error) {
      console.error('Error updating profile:', error);
      setError(`Failed to update profile: ${error.message}`);
    } finally {
      setIsUpdating(false);
    }
  };

  const handleDelete = async () => {
    if (window.confirm('Are you sure you want to delete your account? This action cannot be undone.')) {
      setIsUpdating(true);
      setError(null);
      try {
        await deleteDoc(doc(db, 'users', user.uid));
        console.log('User document deleted from Firestore');

        await user.delete();
        console.log('User deleted from Firebase Auth');

        navigate('/auth?mode=signup');
      } catch (error) {
        console.error('Error deleting account:', error);
        setError(`Failed to delete account: ${error.message}`);
      } finally {
        setIsUpdating(false);
      }
    }
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (!user) {
    return <div className="text-center mt-8 text-white">No user found. Please sign in.</div>;
  }

  return (
    <div className="min-h-screen bg-gray-950 text-white p-8">
      <div className="max-w-md mx-auto bg-gray-950 rounded-lg shadow-lg p-6">
        <h1 className="text-3xl font-extrabold mb-6">User Profile</h1>
        {error && <div className="bg-red-500 text-white p-4 rounded mb-4">{error}</div>}
        {successMessage && <div className="bg-green-500 text-white p-4 rounded mb-4">{successMessage}</div>}
        <form onSubmit={handleUpdate} className="space-y-6">
          <div className="flex justify-center mb-4">
            {image ? (
              <img 
                src={image} 
                alt="Profile" 
                className="w-24 h-24 rounded-full"
                onError={() => setImage(null)}
              />
            ) : (
              <div className="w-24 h-24 flex items-center justify-center">
                <span className="text-3xl">?</span>
              </div>
            )}
          </div>
          <div>
            <label htmlFor="image" className="block text-sm font-medium mb-1 text-gray-300">Profile Image</label>
            <input
              type="file"
              id="image"
              ref={fileInputRef}
              accept="image/*"
              className="w-full px-4 py-2 bg-gray-950 text-white border border-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-accent focus:border-accent"
            />
          </div>
          <div>
            <label htmlFor="name" className="block text-sm font-medium mb-1 text-gray-300">Name</label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-full px-4 py-2 bg-gray-950 text-white border border-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-accent focus:border-accent"
            />
          </div>
          <div>
            <label htmlFor="email" className="block text-sm font-medium mb-1 text-gray-300">Email</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full px-4 py-2 bg-gray-950 text-white border border-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-accent focus:border-accent"
              disabled
            />
          </div>
          <button
            type="submit"
            disabled={isUpdating}
            className="w-full bg-accent text-black font-bold py-2 px-4 rounded-md transition duration-300 ease-in-out hover:opacity-90"
          >
            {isUpdating ? <LoadingSpinner /> : 'Update Profile'}
          </button>
        </form>
        <button
          onClick={handleDelete}
          disabled={isUpdating}
          className="w-full mt-4 bg-red-600 text-gray-300 font-bold py-2 px-4 rounded-md transition duration-300 ease-in-out hover:opacity-90"
        >
          {isUpdating ? <LoadingSpinner /> : 'Delete Account'}
        </button>
      </div>
    </div>
  );
}