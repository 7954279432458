import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { 
  signInWithEmailAndPassword, 
  createUserWithEmailAndPassword, 
  signInWithPopup,  
} from 'firebase/auth';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { 
  doc, 
  setDoc, 
  getDoc, 
  query, 
  where, 
  getDocs, 
  collection, 
  arrayUnion, 
  addDoc,
  FieldValue
} from 'firebase/firestore';
import { auth, googleProvider, db } from '../firebase';

function Auth() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [image, setImage] = useState(null);
  const [mode, setMode] = useState('signin');
  const [error, setError] = useState('');

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const urlMode = searchParams.get('mode');
    if (urlMode === 'signin' || urlMode === 'signup') {
      setMode(urlMode);
    }
  }, [location]);

  const handlePendingInvitations = async (user, email, name) => {
    const invitationsRef = collection(db, "invitations");
    const q = query(invitationsRef, where("inviteeEmail", "==", email), where("status", "==", "OPEN"));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const invitation = querySnapshot.docs[0].data();
      const invitationId = querySnapshot.docs[0].id;

      // Update user document
      await setDoc(doc(db, "users", user.uid), {
        familyId: invitation.familyId,
        name: name
      }, { merge: true });

      // Update family document
      const familyRef = doc(db, "families", invitation.familyId);
      await setDoc(familyRef, {
        members: arrayUnion({
          id: user.uid,
          email: email,
          name: name,
          role: 'member'
        }),
        memberIds: arrayUnion(user.uid)
      }, { merge: true });

      // Update invitation status
      await setDoc(doc(db, "invitations", invitationId), {
        status: "ACCEPTED",
        respondedAt: FieldValue.serverTimestamp()
      }, { merge: true });

      // Create notification for the user who accepted the invitation
      await addDoc(collection(db, "notifications"), {
        userId: user.uid,
        type: "invitation_accepted",
        content: `You have been added to the ${invitation.familyName} family.`,
        isRead: false,
        createdAt: FieldValue.serverTimestamp(),
        linkTo: "/family/dashboard"
      });

      // Create notification for the user who sent the invitation
      await addDoc(collection(db, "notifications"), {
        userId: invitation.inviterUid,
        type: "invitation_accepted",
        content: `${name} has accepted your invitation to join the ${invitation.familyName} family.`,
        isRead: false,
        createdAt: FieldValue.serverTimestamp(),
        linkTo: "/family/dashboard"
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      if (mode === 'signup') {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;

        let imageUrl = null;
        if (image) {
          const storage = getStorage();
          const imageRef = ref(storage, `profileImages/${user.uid}`);
          await uploadBytes(imageRef, image);
          imageUrl = await getDownloadURL(imageRef);
        }

        await setDoc(doc(db, 'users', user.uid), {
          name,
          email,
          imageUrl
        });

        await handlePendingInvitations(user, email, name);

        navigate('/');
      } else {
        await signInWithEmailAndPassword(auth, email, password);
        navigate('/');
      }
    } catch (error) {
      setError(error.message);
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;
      
      // Check if user document already exists
      const userDoc = await getDoc(doc(db, 'users', user.uid));
      
      if (!userDoc.exists()) {
        // If user document doesn't exist, create it with name and email
        await setDoc(doc(db, 'users', user.uid), {
          name: user.displayName,
          email: user.email,
          imageUrl: user.photoURL
        });
  
        // Check for pending invitations
        await handlePendingInvitations(user, user.email, user.displayName);
      }
      
      navigate('/');
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-950">
      <div className="max-w-md w-full space-y-8 bg-gray-950 p-10 rounded-xl border-2 border-accent">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-white">
            {mode === 'signup' ? 'Create an Account' : 'Sign In'}
          </h2>
          {mode === 'signup' && (
            <p className="mt-2 text-center text-sm text-gray-300">
              Start your journey with us today
            </p>
          )}
        </div>
        {error && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
            <span className="block sm:inline">{error}</span>
          </div>
        )}
        <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
          <div className="rounded-md shadow-sm -space-y-px">
            {mode === 'signup' && (
              <div>
                <label htmlFor="name" className="sr-only">Full Name</label>
                <input
                  id="name"
                  name="name"
                  type="text"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-white bg-gray-950 rounded-t-md focus:outline-none focus:ring-accent focus:border-accent focus:z-10 sm:text-sm"
                  placeholder="Full Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            )}
            <div>
              <label htmlFor="email-address" className="sr-only">Email address</label>
              <input
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                required
                className={`appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-white bg-gray-950 ${mode === 'signin' ? 'rounded-t-md' : ''} focus:outline-none focus:ring-accent focus:border-accent focus:z-10 sm:text-sm`}
                placeholder="Email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="password" className="sr-only">Password</label>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-white bg-gray-950 rounded-b-md focus:outline-none focus:ring-accent focus:border-accent focus:z-10 sm:text-sm"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            {mode === 'signup' && (
              <div>
                <label htmlFor="image" className="sr-only">Profile Image</label>
                <input
                  id="image"
                  name="image"
                  type="file"
                  accept="image/*"
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-white bg-gray-950 focus:outline-none focus:ring-accent focus:border-accent focus:z-10 sm:text-sm"
                  onChange={(e) => setImage(e.target.files?.[0] || null)}
                />
              </div>
            )}
          </div>

          <div>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-accent"
            >
              {mode === 'signup' ? 'Create Account' : 'Sign In'}
            </button>
          </div>
        </form>

        <div className="mt-6">
          <div className="relative">
            <div className="absolute inset-0 flex items-center">
              <div className="w-full border-t border-gray-300"></div>
            </div>
            <div className="relative flex justify-center text-sm">
              <span className="px-2 bg-gray-950 text-gray-500">
                Or continue with
              </span>
            </div>
          </div>

          <div className="mt-6">
            <button
              onClick={handleGoogleSignIn}
              className="w-full flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
            >
              <img src="/google-color.svg" alt="Google Logo" width={20} height={20} className="mr-2" />
              <span>Continue with Google</span>
            </button>
          </div>
        </div>

        <div className="mt-6">
          <button
            onClick={() => {
              const newMode = mode === 'signin' ? 'signup' : 'signin';
              setMode(newMode);
              navigate(`/auth?mode=${newMode}`);
            }}
            className="w-full text-center text-sm text-accent hover:text-accent-dark"
          >
            {mode === 'signin' ? 'Need an account? Sign Up' : 'Already have an account? Sign In'}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Auth;