import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db, functions } from '../firebase';
import { doc, getDoc, updateDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import LoadingSpinner from './LoadingSpinner';

function EditGroupPage() {
  const { groupId } = useParams();
  const navigate = useNavigate();
  const [group, setGroup] = useState(null);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [familyDetails, setFamilyDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchGroupAndFamilyDetails = async () => {
      try {
        const groupDoc = await getDoc(doc(db, 'groups', groupId));
        if (groupDoc.exists()) {
          const groupData = groupDoc.data();
          setGroup(groupData);
          setName(groupData.name);
          setDescription(groupData.description);

          // Fetch family details and points
          const familiesRef = collection(db, 'families');
          const familiesQuery = query(familiesRef, where('__name__', 'in', groupData.members));
          const familiesSnapshot = await getDocs(familiesQuery);

          const getFamilyGroupPoints = httpsCallable(functions, 'getFamilyGroupPoints');
          const familyDetailsPromises = familiesSnapshot.docs.map(async (familyDoc) => {
            const familyData = familyDoc.data();
            const pointsResult = await getFamilyGroupPoints({ groupId, familyId: familyDoc.id });
            return {
              id: familyDoc.id,
              name: familyData.name,
              points: pointsResult.data.points
            };
          });

          const familyDetailsResults = await Promise.all(familyDetailsPromises);
          const familyDetailsObject = familyDetailsResults.reduce((acc, family) => {
            acc[family.id] = { name: family.name, points: family.points };
            return acc;
          }, {});

          setFamilyDetails(familyDetailsObject);
        } else {
          setError('Group not found');
        }
      } catch (err) {
        console.error('Error fetching group:', err);
        setError('Error fetching group data');
      } finally {
        setLoading(false);
      }
    };

    fetchGroupAndFamilyDetails();
  }, [groupId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await updateDoc(doc(db, 'groups', groupId), { name, description });
      navigate(`/groups`);
    } catch (err) {
      console.error('Error updating group:', err);
      setError('Error updating group');
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveFamily = async (familyId) => {
    setLoading(true);
    try {
      const removeFamilyFromGroup = httpsCallable(functions, 'removeFamilyFromGroup');
      await removeFamilyFromGroup({ groupId, familyId });
      setGroup(prevGroup => ({
        ...prevGroup,
        members: prevGroup.members.filter(id => id !== familyId)
      }));
      setFamilyDetails(prevDetails => {
        const newDetails = { ...prevDetails };
        delete newDetails[familyId];
        return newDetails;
      });
    } catch (err) {
      console.error('Error removing family:', err);
      setError('Error removing family from group');
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateFamilyPoints = async (familyId, points) => {
    setLoading(true);
    try {
      const updateFamilyGroupPoints = httpsCallable(functions, 'updateFamilyGroupPoints');
      await updateFamilyGroupPoints({ groupId, familyId, points });
      setFamilyDetails(prevDetails => ({
        ...prevDetails,
        [familyId]: { ...prevDetails[familyId], points }
      }));
    } catch (err) {
      console.error('Error updating family points:', err);
      setError('Error updating family points');
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <LoadingSpinner />;
  if (error) return <div className="text-red-500">{error}</div>;

  return (
    <div className="min-h-screen bg-gray-950 text-white p-4 sm:p-8">
      <div className="max-w-4xl mx-auto">
        <h1 className="text-3xl font-bold mb-6">Edit Group: {group.name}</h1>
        <form onSubmit={handleSubmit} className="mb-8">
          <div className="mb-4">
            <label htmlFor="name" className="block mb-2">Group Name</label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-full p-2 bg-gray-800 rounded"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="description" className="block mb-2">Description</label>
            <textarea
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="w-full p-2 bg-gray-800 rounded"
              rows="4"
            ></textarea>
          </div>
          <button type="submit" className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
            Update Group
          </button>
        </form>

        <h2 className="text-2xl font-bold mb-4">Group Families</h2>
        <ul className="list-none pl-0 mb-8">
          {group.members.map((familyId) => (
            <li key={familyId} className="mb-4 p-4 bg-gray-800 rounded flex justify-between items-center">
              <span>{familyDetails[familyId]?.name || 'Unknown Family'}</span>
              <div>
                <input
                  type="number"
                  className="w-20 p-1 mr-2 bg-gray-700 rounded"
                  value={familyDetails[familyId]?.points || 0}
                  onChange={(e) => {
                    const newPoints = parseInt(e.target.value, 10);
                    setFamilyDetails(prevDetails => ({
                      ...prevDetails,
                      [familyId]: { 
                        ...prevDetails[familyId],
                        points: isNaN(newPoints) ? 0 : newPoints 
                      }
                    }));
                  }}
                  onBlur={(e) => handleUpdateFamilyPoints(familyId, parseInt(e.target.value, 10) || 0)}
                />
                <button
                  onClick={() => handleRemoveFamily(familyId)}
                  className="bg-red-600 hover:bg-red-700 text-white font-bold py-1 px-2 rounded"
                >
                  Remove
                </button>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default EditGroupPage;