import React, { useState, useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db, functions } from '../firebase';
import { httpsCallable } from 'firebase/functions';
import LoadingSpinner from './LoadingSpinner';

const EditEventForm = ({ eventId, onComplete }) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [points, setPoints] = useState(0);
  const [status, setStatus] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadEvent = async () => {
      setLoading(true);
      try {
        const eventRef = doc(db, 'events', eventId);
        const eventDoc = await getDoc(eventRef);
        if (eventDoc.exists()) {
          const eventData = eventDoc.data();
          setName(eventData.name);
          setDescription(eventData.description);
          setStartTime(new Date(eventData.startTime.seconds * 1000).toISOString().slice(0, -8));
          setEndTime(new Date(eventData.endTime.seconds * 1000).toISOString().slice(0, -8));
          setPoints(eventData.points);
          setStatus(eventData.status);
        } else {
          setError('Event not found');
        }
      } catch (error) {
        console.error('Error fetching event details:', error);
        setError('Error loading event');
      }
      setLoading(false);
    };

    loadEvent();
  }, [eventId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      if (!name || !description || !startTime || !endTime || points <= 0) {
        throw new Error('Please fill all fields correctly.');
      }

      const updateEvent = httpsCallable(functions, 'updateEvent');
      const result = await updateEvent({
        eventId,
        name,
        description,
        startTime: new Date(startTime).toISOString(),
        endTime: new Date(endTime).toISOString(),
        points: Number(points),
        currentStatus: status
      });

      if (result.data.success) {
        onComplete();
      } else {
        throw new Error(result.data.message || 'Failed to update event');
      }
    } catch (err) {
      console.error('Error updating event:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <p className="text-red-500">{error}</p>;
  }

  return (
    <form onSubmit={handleSubmit} className="p-4 bg-gray-800 rounded-lg shadow-md">
      <h2 className="text-2xl font-bold text-blue-400 mb-4">Edit Event</h2>

      {error && <p className="text-red-500 mb-4">{error}</p>}

      <div className="mb-4">
        <label className="block text-gray-300 mb-2">Event Name</label>
        <input
          type="text"
          className="w-full p-2 bg-gray-900 text-white rounded"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
      </div>

      <div className="mb-4">
        <label className="block text-gray-300 mb-2">Description</label>
        <textarea
          className="w-full p-2 bg-gray-900 text-white rounded"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          required
        />
      </div>

      <div className="mb-4">
        <label className="block text-gray-300 mb-2">Start Time</label>
        <input
          type="datetime-local"
          className="w-full p-2 bg-gray-900 text-white rounded"
          value={startTime}
          onChange={(e) => setStartTime(e.target.value)}
          required
        />
      </div>

      <div className="mb-4">
        <label className="block text-gray-300 mb-2">End Time</label>
        <input
          type="datetime-local"
          className="w-full p-2 bg-gray-900 text-white rounded"
          value={endTime}
          onChange={(e) => setEndTime(e.target.value)}
          required
        />
      </div>

      <div className="mb-4">
        <label className="block text-gray-300 mb-2">Points</label>
        <input
          type="number"
          className="w-full p-2 bg-gray-900 text-white rounded"
          value={points}
          onChange={(e) => setPoints(Number(e.target.value))}
          required
        />
      </div>

      <button
        type="submit"
        disabled={loading}
        className={`w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300 ${
          loading ? 'opacity-50 cursor-not-allowed' : ''
        }`}
      >
        {loading ? 'Updating Event...' : 'Update Event'}
      </button>
    </form>
  );
};

export default EditEventForm;