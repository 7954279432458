import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db, functions } from '../firebase';
import { collection, query, getDocs, limit, startAfter, orderBy, doc, getDoc, where } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import GroupList from './GroupList';
import GroupPageContent from './GroupPageContent';
import CreateGroupForm from './CreateGroupForm';
import JoinGroupForm from './JoinGroupForm';
import LoadingSpinner from './LoadingSpinner';

function GroupDashboard() {
  const [user, setUser] = useState(null);
  const [groups, setGroups] = useState([]);
  const [lastGroup, setLastGroup] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [familyPoints, setFamilyPoints] = useState(null);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showJoinForm, setShowJoinForm] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (authUser) => {
      if (authUser) {
        const userDoc = await getDoc(doc(db, 'users', authUser.uid));
        if (userDoc.exists()) {
          const userData = { ...authUser, ...userDoc.data() };
          setUser(userData);
          loadGroups(userData.familyId);
        } else {
          console.error('User document not found');
          navigate('/auth?mode=signin');
        }
      } else {
        navigate('/auth?mode=signin');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const loadGroups = async (familyId) => {
    setLoading(true);
    try {
      const groupsRef = collection(db, 'groups');
      const q = query(
        groupsRef,
        where('members', 'array-contains', familyId),
        orderBy('createdAt', 'desc'),
        limit(10)
      );
      const querySnapshot = await getDocs(q);
      const groupsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setGroups(groupsData);
      setLastGroup(querySnapshot.docs[querySnapshot.docs.length - 1]);
    } catch (error) {
      console.error('Error loading groups:', error);
    }
    setLoading(false);
  };

  const loadMoreGroups = async () => {
    if (!lastGroup || !user) return;
    setLoading(true);
    try {
      const groupsRef = collection(db, 'groups');
      const q = query(
        groupsRef,
        where('members', 'array-contains', user.familyId),
        orderBy('createdAt', 'desc'),
        startAfter(lastGroup),
        limit(10)
      );
      const querySnapshot = await getDocs(q);
      const newGroups = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setGroups([...groups, ...newGroups]);
      setLastGroup(querySnapshot.docs[querySnapshot.docs.length - 1]);
    } catch (error) {
      console.error('Error loading more groups:', error);
    }
    setLoading(false);
  };

  const handleGroupClick = async (group) => {
    setLoading(true);
    try {
      const getGroupDetails = httpsCallable(functions, 'getGroupDetails');
      const result = await getGroupDetails({ groupId: group.id });
      console.log('Group details:', result.data);
      setSelectedGroup(result.data.group);
      setFamilyPoints(result.data.group.familyPoints);
    } catch (error) {
      console.error('Error fetching group details:', error);
    }
    setLoading(false);
  };

  const handleCreateComplete = () => {
    setShowCreateForm(false);
    loadGroups(user.familyId);
  };

  const handleJoinComplete = () => {
    setShowJoinForm(false);
    loadGroups(user.familyId);
  };

  if (!user) return <LoadingSpinner />;

  return (
    <div className="min-h-screen bg-gray-950 text-white p-4 sm:p-8">
      <div className="max-w-4xl mx-auto">
        <h1 className="text-3xl font-bold mb-6">Groups</h1>
        
        <div className="mb-8">
          <GroupList 
            groups={groups} 
            onGroupClick={handleGroupClick}
            selectedGroupId={selectedGroup?.id}
          />
          {lastGroup && (
            <button 
              onClick={loadMoreGroups}
              className="mt-4 w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300"
            >
              Load More Groups
            </button>
          )}
        </div>

        {loading && <LoadingSpinner />}

        {selectedGroup && (
          <GroupPageContent 
            group={{...selectedGroup, familyPoints}}
            currentUser={user}
            isAdmin={selectedGroup.adminFamilyId === user.familyId}
          />
        )}

        <div className="mt-8 space-y-4">
          <button 
            onClick={() => setShowJoinForm(!showJoinForm)}
            className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300"
          >
            {showJoinForm ? 'Hide Join Group Form' : 'Join Group'}
          </button>

          {showJoinForm && (
            <div className="mt-4">
              <h2 className="text-2xl font-semibold mb-4">Join Group</h2>
              <JoinGroupForm onComplete={handleJoinComplete} />
            </div>
          )}

          <button 
            onClick={() => setShowCreateForm(!showCreateForm)}
            className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300"
          >
            {showCreateForm ? 'Hide Create Group Form' : 'Create New Group'}
          </button>

          {showCreateForm && (
            <div className="mt-4">
              <h2 className="text-2xl font-semibold mb-4">Create New Group</h2>
              <CreateGroupForm onComplete={handleCreateComplete} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default GroupDashboard;