import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Login from './components/Login';
import EventList from './components/EventList';
import CreateEventPage from './components/CreateEventPage';
import EventDetails from './components/EventDetails';
import EditEventForm from './components/EditEventForm';
import Auth from './components/Auth';
import Profile from './components/Profile';
import Navbar from './components/Navbar';
import CreateFamilyForm from './components/CreateFamilyForm';
import FamilyDashboard from './components/FamilyDashboard';
import GroupDashboard from './components/GroupDashboard';
import Invitations from './components/Invitations';
import EditGroupPage from './components/EditGroupPage';
import './firebase';

function App() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIfMobile = () => setIsMobile(window.innerWidth < 768);
    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);
    return () => window.removeEventListener('resize', checkIfMobile);
  }, []);

  return (
    <Router>
      <div className={`min-h-screen bg-gray-950 ${isMobile ? 'pb-16' : ''}`}>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/events" element={<EventList />} />
          <Route path="/events/:eventId" element={<EventDetails />} />
          <Route path="/events/edit/:eventId" element={<EditEventForm />} />
          <Route path="/auth" element={<Auth />} />
          <Route path="/create-family" element={<CreateFamilyForm />} />
          <Route path="/family/dashboard" element={<FamilyDashboard />} />
          <Route path="/groups" element={<GroupDashboard />} />
          <Route path="/invitations" element={<Invitations />} />
          <Route path="/events/create" element={<CreateEventPage />} />
          <Route path="/groups/edit/:groupId" element={<EditGroupPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;