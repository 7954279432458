// src/components/GroupList.js

import React from 'react';

function GroupList({ groups, onGroupClick, selectedGroupId }) {
  return (
    <div className="space-y-4">
      {groups.map((group) => (
        <div
          key={group.id}
          className={`p-4 border rounded-lg cursor-pointer transition-colors ${
            selectedGroupId === group.id ? 'bg-blue-600 text-white' : 'bg-gray-800 hover:bg-gray-700'
          }`}
          onClick={() => onGroupClick(group)}
        >
          <h3 className="text-lg font-semibold">{group.name}</h3>
          <p className="text-sm text-gray-300">{group.description}</p>
        </div>
      ))}
    </div>
  );
}

export default GroupList;