import React, { useState, useEffect } from 'react';
import { auth, db, functions } from '../firebase';
import { httpsCallable } from 'firebase/functions';
import { doc, getDoc } from 'firebase/firestore';

function JoinGroupForm({ onComplete }) {
  const [inviteCode, setInviteCode] = useState('');
  const [error, setError] = useState('');
  const [isFamilyMember, setIsFamilyMember] = useState(false);

  useEffect(() => {
    const checkFamilyMembership = async () => {
      if (auth.currentUser) {
        const userDoc = await getDoc(doc(db, 'users', auth.currentUser.uid));
        if (userDoc.exists() && userDoc.data().familyId) {
          setIsFamilyMember(true);
        }
      }
    };
    checkFamilyMembership();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    if (!auth.currentUser) {
      setError('You must be logged in to join a group.');
      return;
    }

    if (!isFamilyMember) {
      setError('You must be associated with a family to join a group.');
      return;
    }

    try {
      const joinGroup = httpsCallable(functions, 'joinGroup');
      await joinGroup({ inviteCode });
      alert('Successfully joined the group!');
      onComplete();
    } catch (error) {
      console.error('Error joining group:', error);
      setError(error.message || 'An error occurred while joining the group');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      {error && <p className="text-red-500">{error}</p>}
      <div>
        <label htmlFor="inviteCode" className="block text-sm font-medium text-gray-300">
          Invite Code
        </label>
        <input
          type="text"
          id="inviteCode"
          value={inviteCode}
          onChange={(e) => setInviteCode(e.target.value)}
          required
          className="mt-1 block w-full rounded-md border-gray-600 bg-gray-700 text-white shadow-sm focus:border-accent focus:ring focus:ring-accent focus:ring-opacity-50"
        />
      </div>
      <button
        type="submit"
        className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300"
        disabled={!isFamilyMember}
      >
        Join Group
      </button>
      {!isFamilyMember && (
        <p className="text-yellow-500">You must be associated with a family to join a group.</p>
      )}
    </form>
  );
}

export default JoinGroupForm;