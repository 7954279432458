import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import { db, functions } from '../firebase';
import { httpsCallable } from 'firebase/functions';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

function CreateEventForm({ familyId, onComplete }) {
  const [groups, setGroups] = useState([]);
  const [selectedGroupId, setSelectedGroupId] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());
  const [points, setPoints] = useState(0);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [familyAddress, setFamilyAddress] = useState('');

  useEffect(() => {
    const fetchGroups = async () => {
      if (!familyId) {
        console.error("familyId is undefined");
        return;
      }
      
      try {
        const groupQuery = query(collection(db, 'groups'), where('members', 'array-contains', familyId));
        const querySnapshot = await getDocs(groupQuery);
        const groupList = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setGroups(groupList);

        // Fetch family address
        const familyDoc = await getDoc(doc(db, 'families', familyId));
        if (familyDoc.exists()) {
          setFamilyAddress(familyDoc.data().homeAddress || '');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchGroups();
  }, [familyId]);

  useEffect(() => {
    // Calculate points whenever start or end time changes
    const durationInHours = (endTime - startTime) / (1000 * 60 * 60);
    setPoints(Math.ceil(durationInHours));
  }, [startTime, endTime]);

  const handleStartTimeChange = (date) => {
    setStartTime(date);
    if (date > endTime) {
      setEndTime(date);
    }
  };

  const handleEndTimeChange = (date) => {
    if (date < startTime) {
      setEndTime(startTime);
    } else {
      setEndTime(date);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      if (!name || !description || !startTime || !endTime || !selectedGroupId) {
        throw new Error('Please fill all fields correctly.');
      }

      const createEvent = httpsCallable(functions, 'createEvent');
      const result = await createEvent({
        name,
        description,
        startTime: startTime.toISOString(),
        endTime: endTime.toISOString(),
        groupId: selectedGroupId,
        familyId,
        location: familyAddress, // Include the family address
      });

      console.log('Event created:', result.data);
      onComplete(result.data);  // Pass the result to the parent component
    } catch (error) {
      console.error('Error creating event:', error);
      setError(error.message || 'An error occurred while creating the event');
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="p-4 bg-gray-800 rounded-lg shadow-md space-y-4">
      <h2 className="text-2xl font-bold text-blue-400 mb-4">Create New Event</h2>
      {error && <p className="text-red-500">{error}</p>}
      <div>
        <label className="block text-sm font-medium text-gray-300 mb-2">Select Group</label>
        <select
          value={selectedGroupId}
          onChange={(e) => setSelectedGroupId(e.target.value)}
          required
          className="w-full p-2 bg-gray-900 text-white rounded"
        >
          <option value="" disabled>Select a Group</option>
          {groups.map((group) => (
            <option key={group.id} value={group.id}>{group.name}</option>
          ))}
        </select>
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-300 mb-2">Event Name</label>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
          className="w-full p-2 bg-gray-900 text-white rounded"
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-300 mb-2">Description</label>
        <textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          required
          className="w-full p-2 bg-gray-900 text-white rounded"
          rows={3}
        ></textarea>
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-300 mb-2">Location</label>
        <input
          type="text"
          value={familyAddress}
          onChange={(e) => setFamilyAddress(e.target.value)}
          className="w-full p-2 bg-gray-900 text-white rounded"
          placeholder="Event location"
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-300 mb-2">Start Time</label>
        <DatePicker
          selected={startTime}
          onChange={handleStartTimeChange}
          showTimeSelect
          timeFormat="HH:mm"
          timeIntervals={15}
          timeCaption="time"
          dateFormat="MMMM d, yyyy h:mm aa"
          className="w-full p-2 bg-gray-900 text-white rounded"
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-300 mb-2">End Time</label>
        <DatePicker
          selected={endTime}
          onChange={handleEndTimeChange}
          showTimeSelect
          timeFormat="HH:mm"
          timeIntervals={15}
          timeCaption="time"
          dateFormat="MMMM d, yyyy h:mm aa"
          minDate={startTime}
          minTime={new Date(startTime).setHours(0,0,0,0)}
          maxTime={new Date(startTime).setHours(23,59,59,999)}
          className="w-full p-2 bg-gray-900 text-white rounded"
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-300 mb-2">Points</label>
        <p className="text-white">{points}</p>
      </div>
      <button
        type="submit"
        disabled={loading}
        className={`w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300 ${
          loading ? 'opacity-50 cursor-not-allowed' : ''
        }`}
      >
        {loading ? 'Creating Event...' : 'Create Event'}
      </button>
    </form>
  );
}

export default CreateEventForm;