// src/components/UserMenu.js
import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import { signOut } from 'firebase/auth';

export function UserMenu() {
  const [user, setUser] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
    });

    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      unsubscribe();
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  if (!user) {
    return null;
  }

  const displayName = user.displayName || user.email || 'User';
  const initials = displayName.charAt(0).toUpperCase();

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      setIsOpen(false);
      navigate('/'); // Redirect to homepage after successful logout
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <div className="relative" ref={menuRef}>
      <button 
        onClick={() => setIsOpen(!isOpen)} 
        className="flex items-center justify-center text-white hover:bg-gray-950 rounded-full p-2"
      >
        {user.photoURL ? (
          <img 
            src={user.photoURL} 
            alt={displayName} 
            className="rounded-full w-8 h-8"
          />
        ) : (
          <div className="rounded-full bg-gray-900 text-white w-8 h-8 flex items-center justify-center">
            {initials}
          </div>
        )}
      </button>
      {isOpen && (
        <div className="absolute right-0 mt-2 w-48 bg-gray-950 border border-gray-700 rounded-lg shadow-lg">
          <div className="px-4 py-2 border-b border-gray-700">
            <div className="text-sm text-white">{displayName}</div>
            <div className="text-xs text-gray-400">{user.email}</div>
          </div>
          <Link to="/profile" className="block px-4 py-2 text-sm text-white hover:bg-gray-700">Profile</Link>
          <Link to="/family/dashboard" className="block px-4 py-2 text-sm text-white hover:bg-gray-700">Family</Link>
          <Link to="/groups" className="block px-4 py-2 text-sm text-white hover:bg-gray-700">Groups</Link>
          <Link to="/events" className="block px-4 py-2 text-sm text-white hover:bg-gray-700">Events</Link>
          <button onClick={handleSignOut} className="w-full text-left px-4 py-2 text-sm text-white hover:bg-gray-700">Sign Out</button>
        </div>
      )}
    </div>
  );
}