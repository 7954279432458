import React, { useState, useEffect } from 'react';
import { signInWithEmailAndPassword, createUserWithEmailAndPassword, GoogleAuthProvider, signInWithPopup, getRedirectResult } from 'firebase/auth';
import { httpsCallable } from 'firebase/functions';
import { auth, functions } from '../firebase';
import { useNavigate } from 'react-router-dom';

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const provider = new GoogleAuthProvider();
  
    const processInvitations = async () => {
      try {
        const processInvitationsFunction = httpsCallable(functions, 'processUserInvitations');
        const result = await processInvitationsFunction();
        console.log(result.data.message);
      } catch (error) {
        console.error('Error processing invitations:', error);
      }
    };

    const handleSignIn = async (e) => {
      e.preventDefault();
      try {
        await signInWithEmailAndPassword(auth, email, password);
        await processInvitations();
        navigate('/events');
      } catch (error) {
        console.error('Error signing in:', error);
      }
    };
  
    const handleSignUp = async (e) => {
      e.preventDefault();
      try {
        await createUserWithEmailAndPassword(auth, email, password);
        await processInvitations();
        navigate('/events');
      } catch (error) {
        console.error('Error signing up:', error);
      }
    };

    const handleGoogleSignIn = async () => {
      try {
        await signInWithPopup(auth, provider);
        await processInvitations();
        navigate('/events');
      } catch (error) {
        console.error('Error signing in with Google:', error);
      }
    };

    useEffect(() => {
      const handleRedirectResult = async () => {
        try {
          const result = await getRedirectResult(auth);
          if (result) {
            await processInvitations();
            navigate('/events');
          }
        } catch (error) {
          console.error('Error handling redirect result:', error);
        }
      };

      handleRedirectResult();
    }, [navigate]); // Added navigate to dependency array

  return (
    <div>
      <h1>Login</h1>
      <form onSubmit={handleSignIn}>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          required
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          required
        />
        <button type="submit">Sign In</button>
      </form>
      <button onClick={handleSignUp}>Sign Up</button>
      <button onClick={handleGoogleSignIn}>Sign In with Google</button>
    </div>
  );
}

export default Login;
