import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db, storage, functions } from '../firebase';
import { doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { httpsCallable } from 'firebase/functions';
import { useLoadScript, StandaloneSearchBox } from '@react-google-maps/api';
import InviteForm from './InviteForm';
import LoadingSpinner from './LoadingSpinner';

const libraries = ['places'];

function FamilyDashboard() {
  const [family, setFamily] = useState(null);
  const [familyMembers, setFamilyMembers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedFamily, setEditedFamily] = useState(null);
  const [image, setImage] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const fileInputRef = useRef(null);
  const searchBoxRef = useRef(null);
  const navigate = useNavigate();

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  useEffect(() => {
    const fetchData = async (currentUser) => {
      try {
        const familiesRef = collection(db, 'families');
        const q = query(familiesRef, where('memberIds', 'array-contains', currentUser.uid));
        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
          setError('No family found for this user.');
        } else {
          const familyDoc = querySnapshot.docs[0];
          const familyData = { id: familyDoc.id, ...familyDoc.data() };
          setFamily(familyData);
          setEditedFamily(familyData);
          setPreviewUrl(familyData.image);

          const membersData = await Promise.all(
            familyData.members.map(async (member) => {
              const userDoc = await getDoc(doc(db, 'users', member.id));
              return {
                ...member,
                ...userDoc.data(),
              };
            })
          );
          setFamilyMembers(membersData);
        }
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Unable to fetch data. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      if (currentUser) {
        fetchData(currentUser);
      } else {
        navigate('/auth?mode=signin');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const handleEdit = () => {
    const updatedMembers = editedFamily.members.map(member => {
      const fullMemberData = familyMembers.find(fm => fm.id === member.id);
      return { ...member, ...fullMemberData };
    });
    setEditedFamily({ ...editedFamily, members: updatedMembers });
    setIsEditing(true);
  };

  const handleCancel = () => {
    setIsEditing(false);
    setEditedFamily({
      ...family,
      members: family.members.map(member => {
        const fullMemberData = familyMembers.find(fm => fm.id === member.id);
        return { ...member, ...fullMemberData };
      })
    });
    setImage(null);
    setPreviewUrl(family.image);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      setPreviewUrl(URL.createObjectURL(file));
    }
  };

  const handlePlacesChanged = () => {
    const places = searchBoxRef.current.getPlaces();
    if (places && places.length > 0) {
      setEditedFamily({ ...editedFamily, homeAddress: places[0].formatted_address });
    }
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      let imageUrl = editedFamily.image;
      if (image) {
        const imageRef = ref(storage, `family-images/${family.id}/${Date.now()}`);
        await uploadBytes(imageRef, image);
        imageUrl = await getDownloadURL(imageRef);
      }
  
      const simplifiedMembers = editedFamily.members.map(({ id, role }) => ({ id, role }));
  
      const editFamily = httpsCallable(functions, 'editFamily');
      const result = await editFamily({
        familyId: family.id,
        familyName: editedFamily.name,
        homeAddress: editedFamily.homeAddress,
        children: editedFamily.children,
        imageUrl: imageUrl,
        members: simplifiedMembers,
      });
  
      if (result.data.success) {
        setFamily({...family, ...editedFamily, image: imageUrl, members: simplifiedMembers});
        setFamilyMembers(editedFamily.members);
        setIsEditing(false);
      } else {
        throw new Error(result.data.message || 'Failed to edit family');
      }
    } catch (error) {
      console.error('Error editing family:', error);
      setError(`An error occurred: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <div className="text-center mt-8 text-white"><LoadingSpinner /></div>;

  if (error) {
    return (
      <div className="text-center mt-8 text-red-500">
        {error}
        <br />
        <a href="/create-family" className="mt-4 inline-block px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600">
          Create a Family
        </a>
      </div>
    );
  }

  if (!family) {
    return (
      <div className="text-center mt-8 text-white">
        No family found. 
        <a href="/create-family" className="ml-2 text-accent hover:underline">
          Create a Family
        </a>
      </div>
    );
  }

  if (loadError) {
    return <div className="text-center mt-8 text-red-500">Error loading Google Maps API: {loadError.message}</div>;
  }

  if (!isLoaded) {
    return <div className="text-center mt-8 text-white">Loading Google Maps API...</div>;
  }

  const inputClass = 'w-full px-4 py-2 bg-gray-800 text-white border border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500';
  const labelClass = 'block text-sm font-medium text-gray-300 mb-1';

  return (
    <div className="min-h-screen bg-gray-950 text-white p-8">
      <div className="max-w-3xl mx-auto">
        {isEditing ? (
          <div className="space-y-6">
            <h1 className="text-3xl font-extrabold mb-6">Edit {editedFamily.name}</h1>
            <div>
              <label htmlFor="familyImage" className={labelClass}>Family Image</label>
              <div className="flex items-center space-x-4">
                {previewUrl && (
                  <img src={previewUrl} alt="Family" className="w-24 h-24 rounded-full object-cover object-top" />
                )}
                <input
                  type="file"
                  id="familyImage"
                  ref={fileInputRef}
                  accept="image/*"
                  onChange={handleImageChange}
                  className="hidden"
                />
                <button
                  type="button"
                  onClick={() => fileInputRef.current?.click()}
                  className="px-4 py-2 bg-gray-700 text-white rounded-lg hover:bg-gray-600 transition-colors"
                >
                  Choose Image
                </button>
              </div>
            </div>
            <div>
              <label htmlFor="familyName" className={labelClass}>Family Name</label>
              <input
                id="familyName"
                type="text"
                value={editedFamily.name}
                onChange={(e) => setEditedFamily({...editedFamily, name: e.target.value})}
                className={inputClass}
              />
            </div>
            <div>
              <label htmlFor="homeAddress" className={labelClass}>Home Address</label>
              <StandaloneSearchBox
                onLoad={ref => searchBoxRef.current = ref}
                onPlacesChanged={handlePlacesChanged}
              >
                <input
                  id="homeAddress"
                  type="text"
                  value={editedFamily.homeAddress}
                  onChange={(e) => setEditedFamily({...editedFamily, homeAddress: e.target.value})}
                  className={inputClass}
                />
              </StandaloneSearchBox>
            </div>
            <div>
              <label className={labelClass}>Children</label>
              {editedFamily.children.map((child, index) => (
                <input
                  key={index}
                  type="text"
                  value={child}
                  onChange={(e) => {
                    const newChildren = [...editedFamily.children];
                    newChildren[index] = e.target.value;
                    setEditedFamily({...editedFamily, children: newChildren});
                  }}
                  className={`${inputClass} mb-2`}
                />
              ))}
              <button
                onClick={() => setEditedFamily({...editedFamily, children: [...editedFamily.children, '']})}
                className="mt-2 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
              >
                Add Child
              </button>
            </div>
            <div>
              <label className={labelClass}>Family Members</label>
              {editedFamily.members.map((member, index) => (
                <div key={index} className="flex items-center space-x-2 mb-2">
                  <input
                    type="text"
                    value={member.name || member.email || 'Unknown User'}
                    readOnly
                    className={`${inputClass} flex-grow`}
                  />
                  <select
                    value={member.role}
                    onChange={(e) => {
                      const newMembers = [...editedFamily.members];
                      newMembers[index] = {...newMembers[index], role: e.target.value};
                      setEditedFamily({...editedFamily, members: newMembers});
                    }}
                    className={`${inputClass} w-32`}
                  >
                    <option value="admin">Admin</option>
                    <option value="member">Member</option>
                  </select>
                  <button
                    onClick={() => {
                      const newMembers = editedFamily.members.filter((_, i) => i !== index);
                      setEditedFamily({...editedFamily, members: newMembers});
                    }}
                    className="px-2 py-1 bg-red-500 text-white rounded-lg hover:bg-red-600"
                  >
                    Remove
                  </button>
                </div>
              ))}
            </div>
            <div className="flex justify-end space-x-4">
              <button onClick={handleCancel} className="px-4 py-2 bg-gray-600 text-white rounded-lg hover:bg-gray-700">Cancel</button>
              <button onClick={handleSave} className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600">Save</button>
            </div>
          </div>
        ) : (
          <>
            <div className="flex justify-between items-center mb-6">
              <h1 className="text-3xl font-extrabold">{family.name} Dashboard</h1>
              <button onClick={handleEdit} className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600">Edit</button>
            </div>
            {family.image && (
              <img src={family.image} alt="Family" className="w-32 h-32 rounded-full object-cover object-top mb-4" />
            )}
            <p className="mb-4">Address: {family.homeAddress}</p>
            <h2 className="text-2xl font-bold mb-4">Family Members:</h2>
            <ul className="list-disc pl-5 mb-6">
              {familyMembers.map((member, index) => (
                <li key={index}>
                  {member.name || 'Unknown User'} ({member.role})
                </li>
              ))}
            </ul>
            {family.children && family.children.length > 0 && (
              <>
                <h2 className="text-2xl font-bold mb-4">Children:</h2>
                <ul className="list-disc pl-5 mb-6">
                  {family.children.map((child, index) => (
                    <li key={index}>{child}</li>
                  ))}
                </ul>
              </>
            )}
            <InviteForm familyId={family.id} />
          </>
        )}
      </div>
    </div>
  );
}

export default FamilyDashboard;