import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';
import EventActions from './EventActions';
import { Calendar, Clock, Users, Award } from 'lucide-react';

const EventItem = ({ event: initialEvent, currentUserFamilyId, currentUserId }) => {
  const [event, setEvent] = useState(initialEvent);
  const [acceptingFamilyName, setAcceptingFamilyName] = useState('');
  const [creatorFamilyName, setCreatorFamilyName] = useState('');
  const [familyImageUrl, setFamilyImageUrl] = useState(null);
  const navigate = useNavigate();

  const fetchEventData = useCallback(async () => {
    const eventDoc = await getDoc(doc(db, 'events', event.id));
    if (eventDoc.exists()) {
      setEvent({ ...eventDoc.data(), id: eventDoc.id });
    }
  }, [event.id]);

  useEffect(() => {
    setEvent(initialEvent);
  }, [initialEvent]);

  useEffect(() => {
    const fetchFamilyData = async () => {
      const acceptingFamilyId = event.acceptedByFamilyId;
      const creatorFamilyId = event.creatorFamilyId;

      if (acceptingFamilyId) {
        const acceptingFamilyDoc = await getDoc(doc(db, 'families', acceptingFamilyId));
        if (acceptingFamilyDoc.exists()) {
          const familyData = acceptingFamilyDoc.data();
          setAcceptingFamilyName(familyData.name);
          setFamilyImageUrl(familyData.image || null);
        }
      }

      if (creatorFamilyId) {
        const creatorFamilyDoc = await getDoc(doc(db, 'families', creatorFamilyId));
        if (creatorFamilyDoc.exists()) {
          const familyData = creatorFamilyDoc.data();
          setCreatorFamilyName(familyData.name);
          if (!acceptingFamilyId) {
            setFamilyImageUrl(familyData.image || null);
          }
        }
      }
    };

    fetchFamilyData();
  }, [event.acceptedByFamilyId, event.creatorFamilyId]);

  const handleActionComplete = async () => {
    await fetchEventData();
    console.log('Event action completed');
  };

  const isCreator = currentUserFamilyId === event.creatorFamilyId;
  const isRejected = event.rejectedFamilies && event.rejectedFamilies.includes(currentUserFamilyId);

  const formatDate = (date) => {
    return new Date(date.seconds * 1000).toLocaleDateString('en-US', { weekday: 'long', month: 'long', day: 'numeric' });
  };

  const formatTime = (date) => {
    return new Date(date.seconds * 1000).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
  };

  return (
    <div className="relative rounded-xl overflow-hidden shadow-lg bg-gray-950 text-white w-full md:w-80 transition-all duration-300 hover:shadow-xl border-2 border-[#c9fb00]">
      <div className="h-48 relative overflow-hidden">
        {familyImageUrl ? (
          <img
            src={familyImageUrl}
            alt={`${acceptingFamilyName || creatorFamilyName || 'Event'} Family`}
            className="w-full h-full object-contain"
          />
        ) : (
          <div className="w-full h-full bg-gray-700 flex items-center justify-center">
            <span className="text-gray-400">No image available</span>
          </div>
        )}
        <div className="absolute inset-0 bg-gradient-to-t from-gray-900 to-transparent"></div>
        <div className="absolute top-2 left-2 p-2 bg-gray-950 bg-opacity-50 rounded">
          <h2 className="text-lg font-bold text-white">{event.name}</h2>
        </div>
        <div className="absolute bottom-2 right-2 px-2 py-1 rounded text-sm font-semibold" style={{
          backgroundColor: 'rgba(0, 0, 0, 0.6)',
          color: event.status === 'ACCEPTED' ? '#10B981' : event.status === 'OPEN' ? '#c9fb00' : '#6B7280'
        }}>
          {event.status}
        </div>
      </div>
      <div className="p-4">
        <div className="flex flex-wrap items-center justify-between mb-2">
          <div className="flex items-center text-gray-300 mr-2 mb-2">
            <Calendar className="w-4 h-4 mr-1" />
            <span className="text-sm">{formatDate(event.startTime)}</span>
          </div>
          <div className="flex items-center text-gray-300 mb-2">
            <Clock className="w-4 h-4 mr-1" />
            <span className="text-sm">{formatTime(event.startTime)} - {formatTime(event.endTime)}</span>
          </div>
        </div>
        <p className="text-gray-300 mb-4 h-12 overflow-hidden">{event.description || 'No description provided'}</p>
        <div className="space-y-2 mb-4">
          <div className="flex items-center text-gray-300">
            <Users className="w-4 h-4 mr-2" />
            <span className="text-sm">Posted by {creatorFamilyName || 'Unknown Family'} Family</span>
          </div>
          {event.status === 'ACCEPTED' && acceptingFamilyName && (
            <div className="flex items-center text-gray-300">
              <Users className="w-4 h-4 mr-2" />
              <span className="text-sm">Accepted by {acceptingFamilyName} Family</span>
            </div>
          )}
          <div className="flex items-center text-gray-300">
            <Award className="w-4 h-4 mr-2" />
            <span className="text-sm">Points: {event.points}</span>
          </div>
        </div>
        <div className="flex flex-wrap gap-2 items-center justify-between">
          <button
            onClick={() => navigate(`/events/${event.id}`)}
            className="bg-blue-600 text-white px-3 py-1 rounded text-sm hover:bg-blue-700 transition-colors"
          >
            View Details
          </button>
          {!isCreator && (
            <EventActions 
              event={event}
              currentUserFamilyId={currentUserFamilyId}
              onActionComplete={handleActionComplete}
            />
          )}
          {isCreator && (
            <span className="text-yellow-400 text-sm">You created this event</span>
          )}
          {isRejected && (
            <span className="text-red-400 text-sm">You rejected this event</span>
          )}
        </div>
      </div>
    </div>
  );
};

export default EventItem;