import React, { useState, useEffect } from 'react';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useNavigate } from 'react-router-dom';

export default function Invitations() {
  const [invitations, setInvitations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const auth = getAuth();
  const db = getFirestore();
  const functions = getFunctions(undefined, 'australia-southeast1');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchInvitations = async () => {
      if (!auth.currentUser) {
        console.log('No authenticated user');
        setError('User not authenticated');
        setLoading(false);
        return;
      }

      try {
        const invitationsRef = collection(db, 'invitations');
        const q = query(
          invitationsRef,
          where('inviteeEmail', '==', auth.currentUser.email),
          where('status', '==', 'OPEN')
        );

        console.log('Executing query for invitations...');
        const querySnapshot = await getDocs(q);
        console.log('Query completed. Number of docs:', querySnapshot.size);

        const invitationsData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

        console.log('Invitations data:', invitationsData);
        setInvitations(invitationsData);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching invitations:', err);
        setError('Failed to fetch invitations: ' + err.message);
        setLoading(false);
      }
    };

    fetchInvitations();
  }, [auth.currentUser, db]);

  const handleInvitationResponse = async (invitationId, accept) => {
    setLoading(true);
    setError(null);
    try {
      console.log(`Responding to invitation: ${invitationId}, Accept: ${accept}`);
      const respondToInvitation = httpsCallable(functions, 'respondToInvitation');
      const result = await respondToInvitation({ invitationId, accept });
      console.log('Response result:', result);

      if (result.data.success) {
        console.log('Successfully responded to invitation');

        setInvitations((prevInvitations) =>
          prevInvitations.filter((inv) => inv.id !== invitationId)
        );

        setSuccessMessage(accept 
          ? "You've successfully joined the family!" 
          : "You've declined the invitation.");

        setTimeout(() => {
          navigate('/family/dashboard');
        }, 3000);
      } else {
        throw new Error(result.data.error || 'Failed to respond to invitation');
      }
    } catch (error) {
      console.error('Error responding to invitation:', error);
      setError(`Failed to ${accept ? 'accept' : 'reject'} invitation. Please try again.`);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <div className="text-center p-4">Loading invitations...</div>;
  if (error) return <div className="text-center p-4 text-red-500">Error: {error}</div>;

  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold mb-4">Invitations</h2>
      {successMessage && (
        <div className="bg-green-100 border-l-4 border-green-500 text-green-700 p-4 mb-4 animate-fade-in">
          <p className="font-bold">Success!</p>
          <p>{successMessage}</p>
          <p className="text-sm mt-2">Redirecting to family page...</p>
        </div>
      )}
      {invitations.length === 0 ? (
        <p className="text-gray-600">No invitations found</p>
      ) : (
        <ul className="space-y-4">
          {invitations.map((invitation) => (
            <li key={invitation.id} className="bg-white shadow rounded-lg p-4">
              <p className="font-semibold mb-2">Invitation to join: {invitation.familyName}</p>
              <p className="text-sm text-gray-500 mb-2">Invited by: {invitation.inviterName}</p>
              <div className="flex space-x-2">
                <button
                  onClick={() => handleInvitationResponse(invitation.id, true)}
                  className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition-colors"
                  disabled={loading}
                >
                  Accept
                </button>
                <button
                  onClick={() => handleInvitationResponse(invitation.id, false)}
                  className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition-colors"
                  disabled={loading}
                >
                  Reject
                </button>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}